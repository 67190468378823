import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Alvearie/alvearie.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`By Lee Surprenant `}{` `}{` `}{` | `}{` `}{` `}{` Published March 23, 2021`}</p>
    <h3>{`Background`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://ibm.github.io/FHIR"
      }}>{`IBM FHIR Server`}</a>{` implements `}<a parentName="p" {...{
        "href": "https://www.hl7.org/fhir/R4"
      }}>{`HL7 FHIR version 4.0.1 (R4)`}</a>{` and supports the
FHIR REST API for each resource type defined in the specification.`}</p>
    <p>{`However, one thing that neither the HL7 FHIR specification nor the IBM FHIR Server prescribe is the security model for this interface.
Enter SMART on FHIR.`}</p>
    <p><a parentName="p" {...{
        "href": "https://docs.smarthealthit.org"
      }}>{`SMART on FHIR`}</a>{` is a collection of specifications that focus on authentication and authorization on top of HL7 FHIR.
In November of 2018, Boston Children’s Hospital and HL7 published the first of these specifications called the `}<a parentName="p" {...{
        "href": "http://www.hl7.org/fhir/smart-app-launch/"
      }}>{`SMART App Launch Framework`}</a>{`.
This specification extends OAuth 2.0 with custom `}<inlineCode parentName="p">{`scopes`}</inlineCode>{` and the notion of `}<inlineCode parentName="p">{`launch context`}</inlineCode>{`.`}</p>
    <p>{`SMART `}<em parentName="p">{`scopes`}</em>{` are normal OAuth 2.0 scope strings that are used to define an authorization model that is aligned with FHIR resources.
The specification defines the scope strings via a pattern like `}<inlineCode parentName="p">{`<context>/<resourceType>.<permission>`}</inlineCode>{`, where`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`context`}</em>{` is `}<inlineCode parentName="li">{`patient`}</inlineCode>{` or `}<inlineCode parentName="li">{`user`}</inlineCode>{`;`}</li>
      <li parentName="ul"><em parentName="li">{`resourceType`}</em>{` is one of the resource types defined in the HL7 FHIR specification, or `}<inlineCode parentName="li">{`*`}</inlineCode>{` to indicate all resource types; and`}</li>
      <li parentName="ul"><em parentName="li">{`permission`}</em>{` is `}<inlineCode parentName="li">{`read`}</inlineCode>{` or `}<inlineCode parentName="li">{`write`}</inlineCode>{`, or `}<inlineCode parentName="li">{`*`}</inlineCode>{` for read and write.`}</li>
    </ul>
    <p>{`SMART `}<em parentName="p">{`launch context`}</em>{` is what allows a user to launch an application in the context of a given user session or patient record.
SMART App Launch defines two different launch varieties: one for launching apps from an Electronic Health Record (EHR) system and one for launching “standalone” apps.`}</p>
    <p>{`In this post, I walk through how to support standalone app launch with the IBM FHIR Server via `}<a parentName="p" {...{
        "href": "https://www.keycloak.org"
      }}>{`Keycloak`}</a>{`,
an open source identity and access management solution.`}</p>
    <h3>{`Introducing the Alvearie Keycloak Extensions for FHIR`}</h3>
    <p>{`Keycloak is wonderfully extensible and easily configurable.
Users can automate the creation of SMART `}<em parentName="p">{`scopes`}</em>{` through scipting or configuration, or manually create them via the admin console.
However, supporting SMART `}<em parentName="p">{`launch context`}</em>{` is a bit more tricky.`}</p>
    <p>{`In particular, SMART extends the OAuth 2.0 / OpenID Connect token `}<em parentName="p">{`response payload`}</em>{` with a set of `}<a parentName="p" {...{
        "href": "http://www.hl7.org/fhir/smart-app-launch/scopes-and-launch-context/index.html#launch-context-arrives-with-your-access_token"
      }}>{`launch context parameters`}</a>{`. For example, in the case of the `}<inlineCode parentName="p">{`patient`}</inlineCode>{` context, a SMART-enabled client application would expect a `}<inlineCode parentName="p">{`patient`}</inlineCode>{` parameter in the token response payload (alongside the access token).`}</p>
    <p>{`In addition to the above, SMART App Launch also defines a custom `}<inlineCode parentName="p">{`aud`}</inlineCode>{` parameter that clients must pass on their `}<a parentName="p" {...{
        "href": "http://www.hl7.org/fhir/smart-app-launch/#step-1-app-asks-for-authorization"
      }}>{`authorization`}</a>{` request (in addition to the fields required by OAuth 2.0 / OpenID Connect). Per the SMART “best practices” document at `}<a parentName="p" {...{
        "href": "http://docs.smarthealthit.org/authorization/best-practices/#25-access-token-phishing-by-counterfeit-resource-servers"
      }}>{`http://docs.smarthealthit.org/authorization/best-practices/#25-access-token-phishing-by-counterfeit-resource-servers`}</a>{`, authorization servers should validate this field and use this same value for the `}<inlineCode parentName="p">{`aud`}</inlineCode>{` claim in the granted access token.`}</p>
    <p>{`Neither of these OAuth extensions are supported by Keycloak out-of-the-box (nor almost any other commercial Authorization server) and, for these reasons, the IBM team behind the IBM FHIR Server have introduced a set of `}<a parentName="p" {...{
        "href": "https://github.com/Alvearie/keycloak-extensions-for-fhir"
      }}>{`Keycloak extensions`}</a>{` that fill these gaps.`}</p>
    <h3>{`Using the Alvearie Keycloak Extensions for FHIR`}</h3>
    <p>{`The Keycloak extensions for FHIR project currently consists of two components:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`keycloak-config`}</em>{` for automating the configuration of a Keycloak realm in support of standalone app launch with patient context`}</li>
      <li parentName="ul"><em parentName="li">{`keycloak-extensions`}</em>{` which defines a set of extensions that fill the gaps in Keycloak’s support of SMART App Launch:`}
        <ul parentName="li">
          <li parentName="ul">{`an AudienceValidator authenticator for validating that the `}<inlineCode parentName="li">{`aud`}</inlineCode>{` parameter passed to the server matches the desired value`}</li>
          <li parentName="ul">{`a UserAttributeMapper mapper for mapping user attributes into custom fields in the token response payload (rather than claims in the issued tokens)`}</li>
        </ul>
      </li>
    </ul>
    <p>{`To use the extensions:`}</p>
    <ol>
      <li parentName="ol">{`Build the keycloak-extensions project into a jar file and add it to the `}<a parentName="li" {...{
          "href": "https://www.keycloak.org/docs/latest/server_development/#packaging-classes-and-deployment-2"
        }}><inlineCode parentName="a">{`standalone/deployments`}</inlineCode></a>{` directory of your Keycloak installation.`}
        <ul parentName="li">
          <li parentName="ul">{`Note: moving forward, we hope to publish the built artifacts to Maven Central.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Configure a realm in your Keycloak installation for SMART App Launch, either manually or via the `}<inlineCode parentName="li">{`keycloak-config`}</inlineCode>{` project.`}
        <ul parentName="li">
          <li parentName="ul">{`In addition to defining all the SMART scopes, the `}<inlineCode parentName="li">{`keycloak-config`}</inlineCode>{` automation will create a default group called `}<inlineCode parentName="li">{`fhirUser`}</inlineCode>{` (so that all new users will belong to it), and a Group Membership mapper for adding this group to the access token. This group detail is used to map users to a security-role in the IBM FHIR Server (described below).
`}<img parentName="li" {...{
              "src": "https://user-images.githubusercontent.com/5478798/111356103-68e7c680-865e-11eb-8d0c-1c4315a78634.png",
              "alt": "Group-Membership-mapper"
            }}></img></li>
          <li parentName="ul">{`The `}<inlineCode parentName="li">{`keycloak-config`}</inlineCode>{` automation does `}<strong parentName="li">{`not`}</strong>{` yet add a mapper for adding the patient parameter to the token response. To support that, add the `}<inlineCode parentName="li">{`User Attribute (with token response support)`}</inlineCode>{` Mapper to the `}<inlineCode parentName="li">{`launch/patient`}</inlineCode>{` scope and configure it to map a user attribute (e.g. `}<inlineCode parentName="li">{`resourceId`}</inlineCode>{`) to the token response.
`}<img parentName="li" {...{
              "src": "https://user-images.githubusercontent.com/5478798/111354064-4c4a8f00-865c-11eb-9913-e5931e883175.png",
              "alt": "patient-id-mapper"
            }}></img></li>
        </ul>
      </li>
      <li parentName="ol">{`Manually define a new `}<em parentName="li">{`Authentication Flow`}</em>{` that requires Audience Validation before login.
`}<img parentName="li" {...{
          "src": "https://user-images.githubusercontent.com/5478798/111352797-f75a4900-865a-11eb-9850-302cd3c47973.png",
          "alt": "SMART-Authentication-flow"
        }}></img></li>
      <li parentName="ol">{`Add your users and ensure they have a user attribute value that matches the one you mapped in step two.
`}<img parentName="li" {...{
          "src": "https://user-images.githubusercontent.com/5478798/111354473-bb27e800-865c-11eb-8432-94e0a647beaa.png",
          "alt": "User-attribute"
        }}></img></li>
    </ol>
    <h3>{`Configuring the IBM FHIR Server`}</h3>
    <p>{`Now that you have Keycloak configured, its time to hook up the IBM FHIR Server.
The IBM FHIR Server supports `}<a parentName="p" {...{
        "href": "https://openliberty.io/docs/latest/reference/config/server-configuration-overview.html#server-xml"
      }}>{`OpenLiberty configDropins`}</a>{` for configuring server behavior. To configure the server to validate Keycloak-issued tokens, modify `}<inlineCode parentName="p">{`configDropins/disabled/jwtRP.xml`}</inlineCode>{` for you Keycloak installation and move it to `}<inlineCode parentName="p">{`configDropins/overrides/`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<server>
    <!-- Enable features -->
    <featureManager>
        <!-- mpJwt-1.1 is already enabled in the default server.xml, but it doesn't hurt to repeat it here -->
        <feature>mpJwt-1.1</feature>
    </featureManager>

    <!-- Override the application-bnd binding of the main webapp -->
    <webApplication contextRoot="fhir-server/api/v4" id="fhir-server-webapp" location="fhir-server.war" name="fhir-server-webapp">
        <application-bnd id="bind">
            <security-role id="users" name="FHIRUsers">
                <group id="usersGroup" access-id="group:https://\${env.HOSTNAME}/auth/realms/\${env.KEYCLOAK_REALM}/fhirUser"/>
            </security-role>
        </application-bnd>
    </webApplication>

    <!-- This MP JWT configuration validates JWT access tokens and injects them into
         ResourceScoped beans for inspection. -->
    <mpJwt id="jwtConsumer"
           clockSkew="1m"
           jwksUri="http://keycloak:8080/auth/realms/\${env.KEYCLOAK_REALM}/protocol/openid-connect/certs"
           audiences="https://\${env.HOSTNAME}/fhir-server/api/v4"
           userNameAttribute="sub"
           groupNameAttribute="group"
           issuer="https://\${env.HOSTNAME}/auth/realms/\${env.KEYCLOAK_REALM}"
           signatureAlgorithm="RS256"
           authFilterRef="filter"/>

    <authFilter id="filter">
        <requestUrl urlPattern="/fhir-server" />
    </authFilter>
</server>
`}</code></pre>
    <p>{`Additionally, to enforce the SMART scopes passed, the server must be configured with the `}<a parentName="p" {...{
        "href": "https://bintray.com/ibm-watson-health/ibm-fhir-server-releases/fhir-smart"
      }}>{`fhir-smart`}</a>{` persistence interceptor (by dropping the jar file into the `}<inlineCode parentName="p">{`userlib`}</inlineCode>{` directory of your IBM FHIR Server installation).`}</p>
    <p>{`Finaly, to advertise the SMART support and make the auth endpoints discoverable, update the `}<inlineCode parentName="p">{`security`}</inlineCode>{` section of the `}<inlineCode parentName="p">{`fhir-server-config.json`}</inlineCode>{` as described in the `}<a parentName="p" {...{
        "href": "https://ibm.github.io/FHIR/guides/FHIRServerUsersGuide#533-advertise-the-oauth-endpoints-via-fhir-server-config"
      }}>{`IBM FHIR Server User’s Guide`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`...
    "security": {
      "cors": true,
      "basic": {
        "enabled": false
      },
      "certificates": {
        "enabled": false,
        "authFilter": {
          "enabled": false
        }
      },
      "oauth": {
        "enabled": true,
        "regUrl": "https://<host>/auth/realms/\${KEYCLOAK_REALM>}/clients-registrations/openid-connect",
        "authUrl": "https://<host>/auth/realms/\${KEYCLOAK_REALM}/protocol/openid-connect/auth",
        "tokenUrl": "https://<host>/auth/realms/\${KEYCLOAK_REALM}/protocol/openid-connect/token",
        "smart": {
          "enabled": true,
          "scopes": ["openid", "profile", "fhirUser", "launch/patient", "offline_access",
            "patient/*.read",
            "patient/AllergyIntolerance.read",
            "patient/CarePlan.read",
            "patient/CareTeam.read",
            "patient/Condition.read",
            "patient/Device.read",
            "patient/DiagnosticReport.read",
            "patient/DocumentReference.read",
            "patient/Encounter.read",
            "patient/ExplanationOfBenefit.read",
            "patient/Goal.read",
            "patient/Immunization.read",
            "patient/Location.read",
            "patient/Medication.read",
            "patient/MedicationRequest.read",
            "patient/Observation.read",
            "patient/Organization.read",
            "patient/Patient.read",
            "patient/Practitioner.read",
            "patient/PractitionerRole.read",
            "patient/Procedure.read",
            "patient/Provenance.read",
            "patient/RelatedPerson.read"
          ],
          "capabilities": [
            "sso-openid-connect",
            "launch-standalone",
            "client-public",
            "client-confidential-symmetric",
            "permission-offline",
            "context-standalone-patient",
            "permission-patient"
          ]
        }
      }
    },
...
`}</code></pre>
    <h3>{`Conclusion`}</h3>
    <p>{`In this post, we walked through the OAuth 2.0 extensions defined by SMART App Launch, how to support those extensions via the Alvearie Keycloak Extensions for FHIR, and how to use this project together with the IBM FHIR Server to support SMART App Launch.`}</p>
    <p>{`SMART App Launch is just one of the specifications defined by SMART-on-FHIR and the authentication and authorization challenges in healthcare are immense. We’d love to hear from members of the FHIR community, the SMART-on-FHIR community, and the Keycloak community on the approach outlined here and where you’d like to see us go next. Please connect with us on `}<a parentName="p" {...{
        "href": "https://chat.fhir.org/#narrow/stream/179170-smart/topic/Keycloak.20for.20SMART.20authz"
      }}>{`chat.fhir.org`}</a>{`, the `}<a parentName="p" {...{
        "href": "https://github.com/Alvearie/keycloak-extensions-for-fhir"
      }}>{`Keycloak Extensions for FHIR project`}</a>{`, or the `}<a parentName="p" {...{
        "href": "https://github.com/ibm/fhir"
      }}>{`IBM FHIR Server project`}</a>{` about how we can take this to the next level.`}</p>
    <hr></hr>
    <p>{`SMART is a trademark of The Children’s Medical Center Corporation.
FHIR® is the registered trademark of HL7 and is used with the permission of HL7. Use of the FHIR trademark does not constitute endorsement of this product by HL7.
IBM and the IBM logo are trademarks of International Business Machines Corporation, registered in many jurisdictions worldwide.
Other product and service names might be trademarks of IBM or other companies. A current list of IBM trademarks is available on `}<a parentName="p" {...{
        "href": "https://ibm.com/trademark"
      }}>{`https://ibm.com/trademark`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      